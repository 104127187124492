import React from 'react';

function dashboard(props) {
    return (
        <div>
                <p> Hey! I'm the dashboard page</p>
        </div>
    );
}

export default dashboard;